import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const Paragraph = memo(function Paragraph({
  description,
  title,
}: Props) {
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  max-width: 83rem;
  margin: 13.75rem auto 6rem;
  padding: 0 1.875rem;

  @media (max-width: 1023px) {
    margin-top: 10rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 3.0625rem;
  margin-bottom: 3.75rem;

  @media (max-width: 1023px) {
    font-size: 30px;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.75rem;

  strong {
    font-weight: 600;
  }

  ul {
    list-style: initial;
    margin: 1.25rem 1rem 0;
  }
`
